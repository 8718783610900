import Loader from 'react-loaders';
import './index.scss'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useEffect, useRef, useState } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import emailjs from '@emailjs/browser'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const refForm = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000);
    return () => clearTimeout(timer);
  }, [])

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_alczed4',
        'template_whfu0u5',
        refForm.current,
        'd3yJezX9E9nTH7OiJ'
      )
      .then(
        () => {
          alert('Message sent successfully');
          window.location.reload(false);
        },
        (error) => {
          console.log('Failed to send message:', error);
          alert('Message failed to send');
        }
      )
  };

  return (
    <>
      <div className='container contact-page'>
        <div className='text-zone'>
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <div className='form-container'>
            <div className="contact-form">
              <form ref={refForm} onSubmit={sendEmail}>
                <ul>
                  <li className="half">
                    <input placeholder="Name" type="text" name="name" required />
                  </li>
                  <li className="half">
                    <input
                      placeholder="Email"
                      type="email"
                      name="email"
                      required
                    />
                  </li>
                  <li>
                    <input
                      placeholder="Subject"
                      type="text"
                      name="subject"
                      required
                    />
                  </li>
                  <li>
                    <textarea
                      placeholder="Message"
                      name="message"
                      required
                    ></textarea>
                  </li>
                  <li>
                    <input type="submit" className="flat-button" value="SEND" />
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <div className='info-map'>
          Atticus Bute,
          <br />
          New York, NY
          <br />
          atticusbute@gmail.com
        </div>
        <div className='map-wrap'>
          <MapContainer center={[40.80098509819447, -73.95840313669814]} zoom={16}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[40.80098509819447, -73.95840313669814]}>
              <Popup>I'm based in this area!</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="cube-transition" />
    </>
  )
}

export default Contact;