import { useEffect, useState } from 'react'
import Loader from 'react-loaders';
import {
  faNode,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact
} from '@fortawesome/free-brands-svg-icons';

//import c from 'devicon/icons/';
//languages
import html5 from 'devicon/icons/html5/html5-original-wordmark.svg';
import css3 from 'devicon/icons/css3/css3-original-wordmark.svg';
import csharp from 'devicon/icons/csharp/csharp-original.svg';
import cplusplus from 'devicon/icons/cplusplus/cplusplus-original.svg';
import javascript from 'devicon/icons/javascript/javascript-original.svg';
import sass from 'devicon/icons/sass/sass-original.svg';
//frameworks
import dotnetcore from 'devicon/icons/dotnetcore/dotnetcore-original.svg';
import bootstrap from 'devicon/icons/bootstrap/bootstrap-original-wordmark.svg';
import express from 'devicon/icons/express/express-original-wordmark.svg';
import react from 'devicon/icons/react/react-original-wordmark.svg';
import node from 'devicon/icons/nodejs/nodejs-original-wordmark.svg';

//tools
import visualstudio from 'devicon/icons/visualstudio/visualstudio-original-wordmark.svg';
import mongodb from 'devicon/icons/mongodb/mongodb-original-wordmark.svg';
import vscode from 'devicon/icons/vscode/vscode-original-wordmark.svg';
import sqlserver from 'devicon/icons/microsoftsqlserver/microsoftsqlserver-original-wordmark.svg';
import gcloud from 'devicon/icons/googlecloud/googlecloud-original-wordmark.svg';
import azure from 'devicon/icons/azure/azure-original-wordmark.svg';
import aws from 'devicon/icons/amazonwebservices/amazonwebservices-original-wordmark.svg';
import npm from 'devicon/icons/npm/npm-original-wordmark.svg';
import git from 'devicon/icons/git/git-original-wordmark.svg';
import github from 'devicon/icons/github/github-original-wordmark.svg';

import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {

  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4500);
    return () => clearTimeout(timer);
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className='top-zone'>
          <div className="text-zone">
            <h1>
              <AnimatedLetters letterClass={letterClass} strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']} idx={15} />
            </h1>
            <p>
              I have always excelled at problem-solving. Even as a child, I was drawn to the family computer, eagerly exploring its capabilities within the limited screen time I was allowed each day. It quickly became apparent that interacting with a computer was more than just tapping keys and clicking icons—it was a journey of unraveling puzzles and overcoming obstacles. This early fascination laid the foundation for my passion for coding.
            </p>
            <p>
              Today, I find myself deeply enamored with the elegant logic embedded within modern coding languages. Every line of code presents a new challenge to tackle, a new problem to solve. Whether it's crafting a sleek user interface or optimizing the efficiency of an algorithm, I am constantly engaged in the intellectually stimulating process of translating human logic into a language comprehensible to machines. The satisfaction that comes from seeing lines of code come to life, transforming ideas into functional software, is unparalleled.
            </p>
            <p>
              My journey in coding isn't just about mastering languages and frameworks; it's about harnessing the power of technology to create meaningful solutions. With each project I undertake, whether it's a simple website or a complex application, I strive to blend creativity with technical prowess to deliver results that not only meet but exceed expectations. My dedication to continuous learning and my ability to adapt to new technologies ensure that I am always at the forefront of innovation, ready to tackle the challenges of tomorrow's digital landscape.
            </p>
            <hr />
          </div>
          <div className='stage-cube-cont'>
            <div className='cubespinner'>
              <div className="face1">
                <FontAwesomeIcon icon={faNode} color="#DD0031" />
              </div>
              <div className="face2">
                <FontAwesomeIcon icon={faHtml5} color="#F06529" />
              </div>
              <div className="face3">
                <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
              </div>
              <div className="face4">
                <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
              </div>
              <div className="face5">
                <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
              </div>
              <div className="face6">
                <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
              </div>
            </div>
          </div>
        </div>
        <div className="skills-zone">
          <h3>
            <AnimatedLetters letterClass={letterClass} strArray={['T', 'e', 'c', 'h', 'n', 'i', 'c', 'a', 'l', ' ', 'S', 'k', 'i', 'l', 'l', 's',]} idx={23} />
          </h3>
          <hr />
          <h4>Languages</h4>
          <div className="icons language-icons">
            <img src={javascript} alt="javascript" title='JavaScript' />
            <img src={csharp} alt="csharp" title='C#' />
            <img src={cplusplus} alt="cplusplus" title='C++' />
            <img src={html5} alt="html5" title='HTML5' />
            <img src={css3} alt="css3" title='CSS3' />
            <img src={sass} alt="sass" title='Sass' />
          </div>
          <hr />
          <h4>Frameworks</h4>
          <div className="icons framework-icons">
            <img src={dotnetcore} alt="ASP.NET CORE" title='ASP.NET Core' />
            <img src={express} alt="express" title='Express.js' />
            <img src={react} alt="react" title='React.js' />
            <img src={node} alt="node" title='Node.js' />
            <img src={bootstrap} alt="bootstrap" title='Bootstrap' />
          </div>
          <hr />
          <h4>Tools</h4>
          <div className="icons tool-icons">
            <img src={visualstudio} alt="visual studio" title='Visual Studio' />
            <img src={vscode} alt="visual studio code" title='Visual Studio Code' />
            <img src={git} alt="git" title='Git' />
            <img src={github} alt="github" title='Github' />
            <img src={azure} alt="azure" title='Microsoft Azure' />
            <img src={aws} alt="aws" title='Amazon Web Services' />
            <img src={gcloud} alt="google cloud" title='Google Cloud' />
            <img src={npm} alt="node package manager" title='Node Package Manager' />
            <img src={sqlserver} alt="sqlserver management studio" title='SqlServer Management Studio' />
            <img src={mongodb} alt="mongodb" title='MongoDB' />
          </div>
        </div>

      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About;