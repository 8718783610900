import './index.scss';
import Polaroid2 from '../../../assets/images/polaroid2.png';
import { useEffect, useState } from 'react';

const Logo = () => {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) return;
  }, [loaded])

  return (
    <div className="photo-container">
      <img
        className="photo"
        src={Polaroid2}
        alt="Polaroid"
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}

export default Logo;