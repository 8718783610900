import './index.css';

const PortfolioItem = ({title, subtitle, description, image, tags, url}) => {
  return(
    <a className="projcard projcard-orange" target="_blank" rel="noreferrer" href={url}>
      <div className="projcard-innerbox">
        <img className="projcard-img" src={image} alt='project'/>
        <div className="projcard-textbox">
          <div className="projcard-title">{title}</div>
          <div className="projcard-subtitle">{subtitle}</div>
          <div className="projcard-bar"></div>
          <div className="projcard-description">{description}</div>
          <div className="projcard-tagbox">
          {tags.map((tag, index) => (
              <span key={index} className="projcard-tag">{tag}</span>
            ))}
          </div>
        </div>
      </div>
    </a>
  );
}
export default PortfolioItem;