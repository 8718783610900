import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from '../AnimatedLetters';
import PortfolioItem from '../PortfolioItem';
import BugTracker from '../../assets/images/bugTrackerMERN.png';
import GameCatalog from '../../assets/images/crudGameCatalog.png';
import LawDayRun from '../../assets/images/lawDayRun.png';
import TheaterPOS from '../../assets/images/theaterPos.png';
import SportsPro from '../../assets/images/sportsPro.png';
import ResumeImg from '../../assets/images/resume.png';
import Resume from '../../assets/images/AtticusButeResume.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import "./index.scss";

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000);
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="container portfolio-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['P', 'o', 'r', 't', 'f', 'o', 'l', 'i', 'o']}
              idx={15}
            />
          </h1>
          <div className="portfolio-container">
            <PortfolioItem title={"SportsPro Technical Support"} subtitle={"ASP.NET MVC Core"} description={" Led the development of a C# ASP.NET MVC Core web application deployed on Azure, enabling administrators to manage products and customers via SQL Server. Leveraged Entity Framework Core and Razor views."} image={SportsPro} tags={["ASP.NET MVC Core", "C#", "Azure"]} url="https://github.com/atticus-bute/Bute-CaseStudy-AWD1115" />
            <PortfolioItem title={"Bug Tracker"} subtitle={"MERN Stack"} description={"Developed and deployed a full-stack bug tracking web application using the MERN stack on Google Cloud, allowing users to report bugs and developers to manage and classify them. Utilized technologies such as Axios, Dotenv, and Bcrypt."} image={BugTracker} tags={["MongoDB", "Express.JS", "React.JS", "Node.JS", "Google Cloud"]} url="https://github.com/atticus-bute/issue-tracker-backend" />
            <PortfolioItem title={"Movie Theater POS"} subtitle={"Windows Forms App"} description={"Developed an object-oriented Windows Forms application in C# for movie theater point-of-sale operations, incorporating user authentication, transaction management using polymorphism and object inheritance principles."} image={TheaterPOS} tags={["Windows Forms", "C#"]} url="https://github.com/atticus-bute/TheaterPoS" />
            <PortfolioItem title={"Video Game Catalog"} subtitle={"React App"} description={"Designed and implemented a React-based application for managing video game collections with CRUD operations. Created a responsive and user-friendly interface using ReactJS and Bootstrap."} image={GameCatalog} tags={["React.JS", "JSX"]} url="https://github.com/atticus-bute/crud-game-catalog" />
            <PortfolioItem title={"Law Day Run"} subtitle={"HTML and CSS"} description={"Initiated and developed a personal website project using exclusively HTML and CSS. Designed and implemented a visually appealing and functional website, showcasing foundational skills in front-end web development."} image={LawDayRun} tags={["HTML", "CSS"]} url="#" />
          </div>
        </div>
        <a href={Resume} download className="resume">
          <div className="resume-hover">
            <img src={ResumeImg} alt="resume" />
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </a>
      </div>
      <Loader type="ball-grid-pulse" />
    </>
  );
}

export default Portfolio;