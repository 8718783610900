import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import LogoLite from '../../assets/images/logo-a-lite.png';
import Logo from './Logo';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
const Home = () => {
	const [letterClass, setLetterClass] = useState('text-animate');
	const nameArray = ['t', 't', 'i', 'c', 'u', 's,'];
	const jobArray = ['a', ' ', 'f', 'u', 'l', 'l', ' ', 's', 't', 'a', 'c', 'k', ' ', 'd', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.'];

	useEffect(() => {
		const timer = setTimeout(() => {
			setLetterClass('text-animate-hover')
		}, 4700);
		return () => clearTimeout(timer);
	}, [])

	return (
		<>
			<div className='container home-page'>
				<div className='text-zone'>
					<h1>
						<span className={letterClass}>H</span>
						<span className={`${letterClass} _12`}>i,</span>
						<br />
						<span className={`${letterClass} _13`}>I</span>
						<span className={`${letterClass} _14`}>'m</span>
						<img src={LogoLite} alt='Developer' />
						<AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
						<br />
						<AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />

					</h1>
					<h2>full stack developer</h2>
					<div className='button-zone'>
						<Link to="/about" className='flat-button'>ABOUT ME</Link>
						<Link to="/portfolio" className='flat-button'>VIEW MY WORK</Link>
						<Link to="/contact" className='flat-button'>CONTACT ME</Link>
					</div>
				</div>
				<Logo />
			</div>
			<Loader type="ball-scale" />
		</>
	);
}
export default Home;